'use client'

import Logout from '@/app/_components/Header/Logout'
import PopupNotMobileGms from '@/app/_messenger/popup/PopupNotMobileGms'
// import ChevronDown from '@/app/_components/Icon/ChevronDown'
import { useI18n } from '@/locales/client'
import Link from 'next/link'
// import { useState } from 'react'

export default function MobileHeaderLink({
  isLoggedIn,
}: {
  isLoggedIn: boolean
}) {
  const t = useI18n()
  // const [isOpen, setIsOpen] = useState(false)
  return (
    <div className="my-32 flex flex-col gap-8 px-20 text-white">
      <Link href="https://trend.inabooth.io/" target="_blank">
        <span className="c-b3">{t('common.trendLabel')}</span>
      </Link>
      <Link href="/characters">
        <span className="c-b3">{t('common.characterLabel')}</span>
      </Link>
      {/* <div className="flex flex-col" onClick={() => setIsOpen((prev) => !prev)}>
        <div className="c-b3 flex list-none items-center gap-4">
          <span>{t('common.licensingLabel')}</span>
          <div className={`scale-50 ${isOpen ? 'rotate-180' : 'rotate-0'}`}>
            <ChevronDown fill={'white'} />
          </div>
        </div>
        <div
          className={`${isOpen ? 'block' : 'hidden'} flex flex-col gap-8 px-8 pt-8`}>
          <Link href="/licensing">
            <span className="c-b3">{t('common.solutionLabel')}</span>
          </Link>
          <Link href="/faq">
            <span className="c-b3">FAQ</span>
          </Link>
        </div>
      </div> */}
      <Link href="/collabo">
        <span className="c-b3">{t('common.collaboLabel')}</span>
      </Link>
      {/* <Link href="/event">
        <span className="c-b3">{t('common.eventLabel')}</span>
      </Link> */}
      <Link href="https://inafactory.io/" target="_blank">
        <span className="c-b3">{t('common.inafactoryLabel')}</span>
      </Link>
      <PopupNotMobileGms />
      <Link
        className={`hidden items-center gap-x-3 xl:flex`}
        href="https://gm.inabooth.io"
        target="_blank">
        {t('common.gmsLabel')}
        <span className="flex h-18 w-35 items-center justify-center rounded-5 bg-strawberry-milk text-11 font-bold text-cherry">
          Beta
        </span>
      </Link>
      {isLoggedIn && <Logout />}
    </div>
  )
}

import { ConversationOptionsMenu } from '@/app/_messenger/common/ConversationOptionsMenu'
import { useMessengerState } from '@/app/_messenger/hooks/useMessengerState'
import { channel } from '@/app/_messenger/hooks/useWebSocket'
import React from 'react'
import { useConversationUserInfo } from '../hooks/useConversationUserInfo'

function HeaderRoot({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex flex-row items-center justify-between bg-[#2559AA] p-24">
      {children}
    </div>
  )
}

export function ConversationDetailsHeader() {
  const [state, setState] = useMessengerState()
  const userInfo = useConversationUserInfo()

  return (
    <HeaderRoot>
      <svg
        onClick={() => {
          if (state.selectedConversationId)
            channel(state.selectedConversationId, 'leave')

          setState((draft) => {
            const url = new URL(window.location.href)
            url.searchParams.delete('conversation')
            url.searchParams.delete('conversationName')

            if (typeof window !== 'undefined')
              window.history.replaceState(null, '', url.toString())

            draft.selectedConversationId = null
          })
        }}
        width="20"
        height="20"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="cursor-pointer">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.8092 19.2071C16.1997 18.8166 16.1997 18.1834 15.8092 17.7929L10.0163 12L15.8092 6.20711C16.1997 5.81658 16.1997 5.18342 15.8092 4.79289C15.4186 4.40237 14.7855 4.40237 14.3949 4.79289L8.60205 10.5858C7.821 11.3668 7.821 12.6332 8.60205 13.4142L14.3949 19.2071C14.7855 19.5976 15.4186 19.5976 15.8092 19.2071Z"
          fill="white"
        />
      </svg>

      <p className="c-h7 text-white">{userInfo[0]?.displayName || ''}</p>

      <ConversationOptionsMenu iconClassName={'fill-white cursor-pointer'} />
    </HeaderRoot>
  )
}

export function ConversationListHeader({ onClose }: { onClose: () => void }) {
  return (
    <HeaderRoot>
      <p className="c-h7 text-white">채팅</p>
      <svg
        className="cursor-pointer"
        onClick={onClose}
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.7072 5.29289C6.31668 4.90237 5.68351 4.90237 5.29299 5.29289C4.90246 5.68342 4.90246 6.31658 5.29299 6.70711L10.5858 12L5.29289 17.2929C4.90237 17.6834 4.90237 18.3166 5.29289 18.7071C5.68342 19.0976 6.31658 19.0976 6.70711 18.7071L12 13.4142L17.293 18.7071C17.6835 19.0976 18.3167 19.0976 18.7072 18.7071C19.0977 18.3166 19.0977 17.6834 18.7072 17.2929L13.4143 12L18.7071 6.70711C19.0976 6.31658 19.0976 5.68342 18.7071 5.29289C18.3166 4.90237 17.6834 4.90237 17.2929 5.29289L12 10.5857L6.7072 5.29289Z"
          fill="white"
        />
      </svg>
    </HeaderRoot>
  )
}

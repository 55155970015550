import { useMessengerState } from '@/app/_messenger/hooks/useMessengerState'
import { useUnreadConversationsCount } from '@/app/_messenger/hooks/useUnreadConversationCount'
import { cn } from '@/app/_primitives/utils/cn'

function Tab({
  tabKey,
  name,
  badge,
}: {
  tabKey: 'all' | 'unread' | 'read'
  name: string
  badge?: number
}) {
  const [state, setState] = useMessengerState()

  return (
    <button
      type="button"
      onClick={() =>
        setState((draft) => {
          draft.currentTab = tabKey
        })
      }
      className={cn(
        'flex gap-4 h-60 flex-1 flex-row items-center justify-center outline-none c-b3 text-[#8B8B8B]',
        {
          'border border-x-0 border-t-0 border-b-3 border-french-bleu !c-h7 text-french-bleu':
            state.currentTab === tabKey,
        },
      )}>
      {name}

      {Boolean(badge) && (
        <span className="flex aspect-square w-24 flex-row items-center justify-center rounded-full bg-french-bleu text-xs text-white">
          {badge}
        </span>
      )}
    </button>
  )
}

export function Tabs() {
  const [state] = useMessengerState()
  const { data: unreadConversationsCount = 0 } = useUnreadConversationsCount(
    state.open && state.selectedConversationId === null,
  )
  return (
    <div className="flex flex-row items-center border-b border-b-tertiary bg-white">
      <Tab tabKey="all" name="전체" />
      <Tab tabKey="unread" name="안 읽음" badge={unreadConversationsCount} />
      <Tab tabKey="read" name="읽음" />
    </div>
  )
}

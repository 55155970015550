import { SvgIconProps } from '@/types/helpers'

export default function ChattingAlarm(props: SvgIconProps) {
  return (
    <svg
      width="27"
      height="28"
      viewBox="0 0 27 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.93161 7.43586C2.93161 5.62323 4.40103 4.15381 6.21366 4.15381H21.5299C23.3425 4.15381 24.812 5.62323 24.812 7.43586V18.376C24.812 20.1887 23.3425 21.6581 21.5299 21.6581H9.8918C9.63587 21.6581 9.38804 21.7478 9.19143 21.9117L6.52039 24.1375C5.09526 25.3251 2.93161 24.3117 2.93161 22.4566V7.43586ZM8.4017 10.7179C8.4017 10.1137 8.8915 9.62389 9.49571 9.62389H18.2479C18.8521 9.62389 19.3419 10.1137 19.3419 10.7179C19.3419 11.3221 18.8521 11.8119 18.2479 11.8119H9.49571C8.8915 11.8119 8.4017 11.3221 8.4017 10.7179ZM9.49571 14C8.8915 14 8.4017 14.4898 8.4017 15.094C8.4017 15.6982 8.8915 16.188 9.49571 16.188H13.8718C14.476 16.188 14.9658 15.6982 14.9658 15.094C14.9658 14.4898 14.476 14 13.8718 14H9.49571Z"
        fill="white"
      />
    </svg>
  )
}

'use client'

import { usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'
import { Sheet } from '../ui/sheet'

type Props = {
  children: React.ReactNode
}
export default function SheetProvider({ children }: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const pathname = usePathname()

  useEffect(() => {
    setIsOpen(false)
  }, [pathname])

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      {children}
    </Sheet>
  )
}

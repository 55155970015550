'use client'

import Image from 'next/image'
import stamplogo from './stamp-logo.svg'

export function LogoImage({
  width,
  height,
  scale,
}: {
  width: number
  height: number
  scale?: boolean
}) {
  return (
    <Image
      src={stamplogo}
      width={width}
      height={height}
      className={scale ? 'scale-[1.03]' : ''}
      alt="footer-stamp-logo"
    />
  )
}

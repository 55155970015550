import { fetchUnreadConversationsCount } from '@/app/_messenger/actions'
import { useQuery } from '@tanstack/react-query'

export function useUnreadConversationsCount(enabled: boolean) {
  return useQuery({
    enabled,
    queryKey: ['conversations:unread-count'],
    queryFn: () => fetchUnreadConversationsCount(),
  })
}

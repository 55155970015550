import { SvgIconProps } from '@/types/helpers'

export default function ArrowForward(props: SvgIconProps) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill={props?.fill || '#3C3C3C'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M13.7929 17.7929C13.4024 18.1834 13.4024 18.8166 13.7929 19.2071C14.1834 19.5976 14.8166 19.5976 15.2071 19.2071L21 13.4142C21.781 12.6332 21.781 11.3668 21 10.5858L15.2071 4.79289C14.8166 4.40237 14.1834 4.40237 13.7929 4.79289C13.4024 5.18342 13.4024 5.81658 13.7929 6.20711L18.5858 11H4.5C3.94772 11 3.5 11.4477 3.5 12C3.5 12.5523 3.94772 13 4.5 13H18.5858L13.7929 17.7929Z"
        fill="currentFill"
      />
    </svg>
  )
}

'use client'

import { useChangeLocale, useCurrentLocale } from '@/locales/client'
import Image from 'next/image'
import Dropdown, { Options } from './Shared/Dropdown'
import JapanFlag from './japanese.svg'
import KoreanFlag from './korean.svg'

const languages = [
  {
    id: 'ko',
    content: (
      <div className="flex items-center gap-8 px-8 py-12">
        <Image
          src={KoreanFlag}
          alt={'KoreanFlag'}
          width={20}
          height={20}
          className="!h-20 !w-20"
        />
        <p>한국어</p>
      </div>
    ),
  },
  {
    id: 'ja',
    content: (
      <div className="flex items-center gap-8 px-8 py-12">
        <Image
          src={JapanFlag}
          alt={'JapanFlag'}
          width={20}
          height={20}
          className="!h-20 !w-20"
        />
        <p>日本語</p>
      </div>
    ),
  },
]

export default function LanguageSwitcher() {
  const changeLocale = useChangeLocale()
  const currentLanguage = languages.filter(
    // eslint-disable-next-line react-hooks/rules-of-hooks
    (lang) => lang.id === useCurrentLocale(),
  )[0]

  return (
    <Dropdown
      className="h-46 w-full bg-[#3B6AB2] font-[500] text-white"
      options={languages}
      selected={currentLanguage}
      onSelect={(option: Options) => {
        changeLocale(option.id as 'ko' | 'ja')
      }}>
      Language
    </Dropdown>
  )
}

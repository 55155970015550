'use client'

import { useI18n } from '@/locales/client'
import Link from 'next/link'

export default function BtnMenu() {
  const t = useI18n()
  return (
    <div className="flex items-center gap-28 text-white">
      <Link href="/signup">{t('common.signUpLabel')}</Link>
      <Link href="/login">{t('common.signInLabel')}</Link>
    </div>
  )
}

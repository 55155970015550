import { Conversations } from '@/app/_messenger/common/Conversations'
import { ConversationListHeader } from '@/app/_messenger/popup/Headers'
import { PopupMessengerRoot } from '@/app/_messenger/popup/PopupMessengerRoot'
import { ShowAllConversations } from '@/app/_messenger/popup/ShowAllConversations'
import { Tabs } from '@/app/_messenger/popup/Tabs'

export function ConversationList({ onClose }: { onClose: () => void }) {
  return (
    <PopupMessengerRoot>
      <ConversationListHeader onClose={onClose} />
      <Tabs />
      <Conversations
        className={
          'flex h-522 flex-col items-stretch overflow-y-auto bg-white pt-12'
        }
      />
      <ShowAllConversations />
    </PopupMessengerRoot>
  )
}

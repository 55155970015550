import React from 'react'

export function PopupMessengerRoot({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <div className="relative flex h-full flex-col items-stretch rounded-20 bg-white">
      {children}
    </div>
  )
}

'use client'

import { useI18n } from '@/locales/client'

export default function Logout() {
  const handleSignOut = () => {
    window.location.href = '/api/auth/logout'
  }
  const t = useI18n()
  return (
    <button type="button" onClick={handleSignOut} className="text-left">
      <span className="c-b3 text-[rgba(116,_164,_238,_1)]">
        {t('common.signOutLabel')}
      </span>
    </button>
  )
}

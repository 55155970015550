import { useMessengerState } from '@/app/_messenger/hooks/useMessengerState'
import { ConversationDetails } from '@/app/_messenger/popup/ConversationDetails'
import { ConversationList } from '@/app/_messenger/popup/ConversationList'

export function PopupMessenger({ onClose }: { onClose: () => void }) {
  const [state] = useMessengerState()

  if (state.selectedConversationId) {
    return <ConversationDetails />
  }

  return <ConversationList onClose={onClose} />
}

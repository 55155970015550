import { SvgIconProps } from '@/types/helpers'

export default function Trend(props: SvgIconProps) {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="currentFill"
      {...props}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.30114 14.084C0.993215 13.7761 0.993214 13.2769 1.30114 12.9689L5.63576 8.63433C5.94342 8.32666 6.44216 8.32636 6.75019 8.63367L9.55395 11.4308L17.0545 3.90166C17.3619 3.59315 17.8611 3.5922 18.1696 3.89954C18.4781 4.20688 18.4791 4.70612 18.1717 5.01463L10.1143 13.1028C9.96662 13.251 9.76611 13.3345 9.55689 13.3348C9.34767 13.3351 9.1469 13.2523 8.99878 13.1045L6.19396 10.3063L2.41623 14.084C2.10831 14.392 1.60906 14.392 1.30114 14.084Z"
        // fill="currentFill"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6946 4.45823C10.6946 4.02276 11.0477 3.66974 11.4831 3.66974H17.6132C18.0487 3.66974 18.4017 4.02276 18.4017 4.45823V10.5883C18.4017 11.0238 18.0487 11.3768 17.6132 11.3768C17.1777 11.3768 16.8247 11.0238 16.8247 10.5883V5.24672H11.4831C11.0477 5.24672 10.6946 4.8937 10.6946 4.45823Z"
        // fill="currentFill"
      />
    </svg>
  )
}

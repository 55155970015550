'use client'

import { useI18n } from '@/locales/client'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import Trend from '../Icon/Trend'

export default function HeaderLinks({ appEnv }: { appEnv?: string }) {
  const pathname = usePathname()
  const t = useI18n()
  return (
    <div className="flex items-center gap-32 text-white xl:gap-18 2xl:gap-32">
      <Link
        className="flex items-center justify-center gap-5 fill-white hover:fill-butterscotch hover:text-butterscotch"
        href="https://trend.inabooth.io/">
        <Trend />
        {t('common.trendLabel')}
      </Link>
      <Link
        className={`hover:text-butterscotch ${
          pathname.match('/characters') ? 'text-butterscotch' : ''
        }`}
        href="/characters">
        {t('common.characterLabel')}
      </Link>
      <Link
        className={`hover:text-butterscotch ${
          pathname.match('/collabo') || pathname.match('/event')
            ? 'text-butterscotch'
            : ''
        }`}
        href="/collabo">
        {t('common.collaboLabel')}
      </Link>
      {/* <Link
        className={`hover:text-butterscotch ${
          pathname.match('/event') ? 'text-butterscotch' : ''
        }`}
        href="/event">
        {t('common.eventLabel')}
      </Link> */}
      <Link
        className="hover:text-butterscotch"
        href="https://inafactory.io/"
        target="_blank">
        {t('common.inafactoryLabel')}
      </Link>
      <Link
        className={`flex items-center gap-x-3 hover:text-butterscotch`}
        href={`https://${appEnv === 'prod' || appEnv === 'production' ? 'gm.inabooth.io' : appEnv === 'preview' ? 'dev.gms.inabooth.io' : 'gms.inabooth.localhost'}`}
        target="_blank">
        {t('common.gmsLabel')}
        <span className="flex h-18 w-35 items-center justify-center rounded-5 bg-strawberry-milk text-11 font-bold text-cherry">
          Beta
        </span>
      </Link>
    </div>
  )
}

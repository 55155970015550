'use client'

import { Button } from '@/app/_primitives/Button'
import { useI18n } from '@/locales/client'
import Link from 'next/link'

export default function CollaboRegisterBtn() {
  const t = useI18n()
  return (
    <Button
      asChild
      type="button"
      size="sm"
      color="milkyWhite"
      className="flex items-center justify-center">
      <Link href={'/collabo/create'}>{t('common.openCollaboLabel')}</Link>
    </Button>
  )
}

'use client'

import Skeleton from '@/app/_components/Shared/Skeleton'
import { TypingIndicator } from '@/app/_messenger/common/TypingIndicator'
import { useSelectedConversation } from '@/app/_messenger/hooks/useSelectedConversation'
import { ConversationDetailsHeader } from '@/app/_messenger/popup/Headers'
import { PopupMessengerRoot } from '@/app/_messenger/popup/PopupMessengerRoot'
import { useSession } from '@/auth/session.hook'
import { ChatMessages } from '../common/ChatMessages'
import { ClientDataPreview } from '../common/ClientDataPreview'
import { SendMessageFormWrapper } from '../common/SendMessageFormWrapper'

export function ConversationDetails() {
  const {
    query,
    messages,
    ref,
    rootRef,
    scrollableRootRef,

    lastScrollDistanceToBottomRef,
    conversationId,
    scrollToLastMessage,
  } = useSelectedConversation()

  const session = useSession()
  const currentUserType = session.data?.user.type

  return (
    <PopupMessengerRoot>
      {currentUserType !== 'user' && <ClientDataPreview />}
      <ConversationDetailsHeader />
      <div
        ref={(node) => {
          rootRef(node)
          scrollableRootRef.current = node
        }}
        onScroll={() => {
          const rootNode = scrollableRootRef.current
          if (rootNode) {
            lastScrollDistanceToBottomRef.current =
              rootNode.scrollHeight - rootNode.scrollTop
          }
        }}
        className="grow overflow-scroll bg-white">
        <div className="flex flex-col-reverse gap-8 px-24 py-20">
          <TypingIndicator scroll={scrollToLastMessage} />
          <ChatMessages conversationId={conversationId!} messages={messages} />
          {(query.isLoading || query.isFetchingNextPage) && (
            <div className="py-12">
              <Skeleton className="h-48 w-full rounded-xl" />
            </div>
          )}
          {!query.isFetchingNextPage && query.hasNextPage && (
            <div ref={ref} className="py-12">
              <Skeleton className="h-48 w-full rounded-xl" />
            </div>
          )}
        </div>
      </div>
      {/*
       * secondMessage = messages[0] becaues messages are ordered desc with createdAt
       * messages[0] will be secondMessage if there is exactly two message
       */}
      <SendMessageFormWrapper
        backdropTop={72}
        secondMessage={messages[0]}
        messageCount={messages.length}
      />
    </PopupMessengerRoot>
  )
}
